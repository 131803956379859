/* Basic styling for the body and root */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: var(--background-color);
    color: var(--text-color);
    height: 100%;
    overflow-y: hidden;
}

html, #root {
    height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

/* Theme Variables */
body.light {
    --background-color: #f0f0f0;
    --header-footer-background: #f7fafc;
    --text-color: RGBA(16, 17, 18, 0.80);
    --button-background: #00000000;
    --button-text-color: #4299e1;
    --button-text-color-hover: #ffffff;
    --button-border: #4299e1;
    --button-hover-background: #0056b3;
    --dark-hover-color: #28a745; /* Dark theme hover color */
}

body.dark {
    --background-color: #1a1a1a;
    --header-footer-background: #28a745;
    --text-color: #fff;
    --button-background: #28a745;
    --button-text-color: #fff;
    --button-border: #28a745;
    --button-hover-background: #218838;
    --light-hover-color: #007bff; /* Light theme hover color */
}
