/* General Styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: var(--background-color);
    color: var(--text-color);
    height: 100vh;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

html, #root {
    height: 100%;
}

/* App Layout */
.app-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

/* Header and Footer Styling */
.header, .footer {
    padding: 5px 20px; /* Adjust padding for smaller height */
    background-color: var(--header-footer-background);
    color: var(--text-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header {
    height: 18px; /* Smaller height */
    position: relative;
}

.footer {
    height: 30px; /* Smaller height */
    position: relative;
    justify-content: center;
    text-align: center;
}

/* Main Content Styling */
.main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    overflow-y: auto; /* Allows content to scroll */
}

/* Centered Container Styling */
.centered-container {
    background: var(--background-color);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 1000px;

}

/* Functions Container */
.functions-container {
    display: flex;
    justify-content: center; /* Center the functions-wrapper container */
    width: 100%;
    padding: 20px;
}

.functions-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px; /* Set a max-width */
}

.read-functions, .write-functions {
    width: 48%;
    background: var(--background-color);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

/* Headings */
h1, h2, h3 {
    color: var(--text-color);
}

/* Input Containers */
.input-container {
    margin: 10px 0;
    width: 100%;
    max-width: 400px;
}

.input-container label {
    display: block;
    margin-bottom: 5px;
    color: var(--text-color);
}

.input-container input {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

/* Buttons */
button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin: 5px 0;
    display: inline-block;
}

button.connect-button {
    background: var(--button-background);
    color: var(--button-text-color);
    border: 1px solid var(--button-border);
}

button.load-button {
    background: var(--button-background);
    color: var(--button-text-color);
    border: 1px solid var(--button-border);
}

button.call-button {
    background: var(--button-background);
    color: var(--button-text-color);
    border: 1px solid var(--button-border);
}

button.send-button {
    background: var(--button-background);
    color: var(--button-text-color);
    border: 1px solid var(--button-border);
}

button:hover {
    background: var(--button-hover-background);
    border-color: var(--button-border-hover);
    color: var(--button-text-color-hover);
}

/* Error Message Styling */
.error {
    color: red;
}

/* Note Styling */
.note {
    font-size: 14px;
    color: var(--text-color);
    text-align: center;
    width: 100%;
}

/* Theme Switcher Dropdown */
/* This CSS makes the theme switcher go to the right */
.theme-switcher {
    position: relative;
    display: inline-block;
    margin-left: auto;
}

.theme-switcher .dropdown-content {
    display: none;
    position: absolute;
    top: 39px;
    right: 2px;
    background-color: var(--background-color);
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    font-size: 12px; /* Set the font size */
}

.theme-switcher:hover .dropdown-content {
    display: block;
}

.theme-switcher button {
    background: none;
    color: var(--text-color);
    border: none;
    font-size: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.theme-switcher button img {
    width: 20px; /* Set the size for the icons */
    height: 20px;
    margin-right: 8px; /* Space between the icon and text */
}

.theme-switcher .dropdown-content button {
    width: 100%;
    padding: 10px;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 12px; /* Set the font size for dropdown items */
    display: flex;
    align-items: center;
}

.theme-switcher .dropdown-content button img {
    width: 20px; /* Set the size for the icons */
    height: 20px;
    margin-right: 8px; /* Space between the icon and text */
    filter: grayscale(100%); /* Make icons grayscale by default */
}

.theme-switcher .dropdown-content button:hover img,
.theme-switcher .dropdown-content button.active img {
    filter: none; /* Remove grayscale filter on hover or when active */
}

/* Highlight non-active theme icon and text on hover */
.theme-switcher .dropdown-content button:not(.active):hover {
    color: var(--button-text-color); /* Text color for hover */
}

/* Light Theme Variables */
body.light .theme-switcher .dropdown-content button:not(.active):hover {
    background: var(--dark-hover-color); /* Dark theme color */
}

/* Dark Theme Variables */
body.dark .theme-switcher .dropdown-content button:not(.active):hover {
    background: var(--light-hover-color); /* Light theme color */
}
